import { MenuItem } from 'primeng/api';

export const systemMenu: MenuItem = {
  label: 'System',
  icon: 'pi pi-desktop',
  routerLink: ['/system'],
  items: [
    { label: 'Schools', routerLink: ['/system/schools-management'] },
    { label: 'ERP', routerLink: ['/erp/schools'] },
    {
      label: 'Overwrite Password',
      routerLink: ['/system/overwrite-password']
    },
    {
      label: 'Overwrite Email Address',
      routerLink: ['/system/overwrite-email-address']
    },
    {
      label: 'Exercise Records Browser',
      routerLink: ['/system/exercise-records-browser']
    },
    {
      label: 'Research Registration',
      routerLink: ['/research/registration-list']
    },
    {
      label: 'Free Trial Registrations',
      routerLink: ['/freeConsultation/registration-list']
    },
    {
      label: 'Payment Records',
      routerLink: ['/system/payment-records']
    },
    {
      label: 'Push Notification',
      routerLink: ['/system/push-notification']
    },
    {
      label: 'Email Messages',
      routerLink: ['/system/email-messages-management']
    },
    {
      label: 'Subscription Management',
      routerLink: ['/system/subscription-management']
    },
    {
      label: 'Users Management',
      routerLink: ['/system/users-management']
    },
    {
      label: 'Vocab Lesson',
      routerLink: ['/system/vocab-lesson-management']
    },
    {
      label: 'Excel Management',
      routerLink: ['/system/excel-management']
    },
    // {
    //   label: 'DictEntryQueries',
    //   routerLink: ['/system/dict-entry-queries-management']
    // },
    // {
    //   label: 'Custom DictEntry',
    //   routerLink: ['/system/custom-dict-entry-managment']
    // },
    // {
    //   label: 'Additional Explanations',
    //   routerLink: ['/system/additional-explanations']
    // },
    {
      label: 'Word Suggestion Management',
      routerLink: ['/system/word-suggestion-management']
    },
    {
      label: 'Records Migration',
      routerLink: ['/system/records-migration']
    },
    {
      label: 'User Report Subscription Management',
      routerLink: ['/system/user-report-subscription-management']
    },
    {
      label: 'Fax Campaign',
      routerLink:['/system/fax-campaign-management']
    },
    {
      label: 'Email Campaign',
      routerLink:['/system/email-campaign-management']
    },
    {
      label: 'Coupon Management',
      routerLink:['/system/coupon-management']
    }
  ]
};
